.rdrCalendarWrapper {
    box-shadow: 0px 4px 20px rgba(149, 162, 233, 0.3);
    font-family: Lato;
    font-style: normal;
}

.rdrMonthAndYearPickers {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
}

.rdrDateDisplayWrapper {
    display: none;
}

.rdrMonthAndYearWrapper {
    margin-bottom: 16px;
}

.rdrMonth {
    padding: 0 40px 1.666em 40px;
}

.rdrNextPrevButton {
    background-color: transparent;
    margin: 0;
}

.rdrNextButton {
    margin-right: 44px;
}

.rdrPprevButton {
    margin-left: 44px;
}

.rdrNextPrevButton:hover {
    background-color: transparent;
}

.rdrNextButton i, .rdrPprevButton i {
    display: none;
}

.rdrNextButton:after {
    content: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9%200.984375L17.0156%209L9%2017.0156L7.59375%2015.6094L13.1719%209.98438H0.984375V8.01562H13.1719L7.59375%202.39062L9%200.984375Z%22%20fill%3D%22%23F58158%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    visibility: visible;
    display: block;
}

.rdrPprevButton:after {
    content: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M17.0156%208.01562V9.98438H4.82812L10.4062%2015.6094L9%2017.0156L0.984375%209L9%200.984375L10.4062%202.39062L4.82812%208.01562H17.0156Z%22%20fill%3D%22%23F58158%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    visibility: visible;
    display: block;
}

.rdrWeekDay {
    color: #2851C8;
    font-weight: 700;
    font-size: 12px;
    line-height: 40px;
}
.rdrSelected{
    background-color: rgb(253, 220, 209);
}
.rdrDay {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    height: 3.4em;
}

.rdrDayNumber span {
    color: #7A8A9C;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: #7A8A9C;
}

.rdrDayNumber span:after {
    display: none;
}